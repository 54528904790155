import __request__ from 'routing/request';
interface ExecutionState {
    readonly parameter_bindings: {
        readonly [_key: string]: Binding;
    };
    readonly global_override: ExecutionStateGlobalOverride;
    readonly additional_parameters: {
        readonly [_key: string]: Object;
    };
    readonly queries: {
        readonly [_key: string]: ExecutionStateGlobalOverride;
    };
}
interface Query {
    readonly filter: Filter;
    readonly timerange: TimeRange;
    readonly query: BackendQuery;
    readonly id: string;
    readonly filters: UsedSearchFilter[];
    readonly search_types: SearchType[];
}
interface QueryExplainResult {
    readonly search_types: {
        readonly [_key: string]: ExplainResult;
    };
}
interface QueryDTO {
    readonly filter: Filter;
    readonly timerange: TimeRange;
    readonly query: BackendQuery;
    readonly id: string;
    readonly filters: UsedSearchFilter[];
    readonly search_types: SearchType[];
}
interface SearchJobDTO {
    readonly owner: string;
    readonly execution: ExecutionInfo;
    readonly id: string;
    readonly results: {
        readonly [_key: string]: QueryResult;
    };
    readonly errors: SearchError[];
    readonly search_id: string;
    readonly executing_node: string;
}
interface SearchDTOv2 {
    readonly id: string;
    readonly parameters: Parameter[];
    readonly queries: QueryDTOv2[];
}
interface ExecutionInfo {
    readonly cancelled: boolean;
    readonly completed_exceptionally: boolean;
    readonly done: boolean;
}
type SearchDTOArray = SearchDTO[];
interface Result {
    readonly name: string;
    readonly id: string;
    readonly type: string;
}
interface QueryResult {
    readonly query: Query;
    readonly execution_stats: QueryExecutionStats;
    readonly search_types: {
        readonly [_key: string]: Result;
    };
    readonly state: 'INCOMPLETE' | 'FAILED' | 'COMPLETED';
    readonly errors: SearchError[];
}
interface QueryDTOv2 {
    readonly timerange: TimeRange;
    readonly query: BackendQuery;
    readonly streams: string[];
    readonly id: string;
    readonly filters: UsedSearchFilter[];
    readonly search_types: SearchType[];
}
interface QueryExecutionStats {
    readonly duration: number;
    readonly effective_timerange: AbsoluteRange;
    readonly timestamp: string;
}
interface SearchDTO {
    readonly skip_no_streams_check: boolean;
    readonly id: string;
    readonly parameters: Parameter[];
    readonly queries: QueryDTO[];
}
interface SearchType {
    readonly filter: Filter;
    readonly timerange: unknown;
    readonly query: BackendQuery;
    readonly streams: string[];
    readonly name: string;
    readonly id: string;
    readonly filters: UsedSearchFilter[];
    readonly type: string;
}
interface ExplainResult {
    readonly searched_index_ranges: IndexRangeResult[];
    readonly query_string: string;
}
interface Binding {
    readonly type: string;
}
interface UsedSearchFilter {
}
interface AbsoluteRange {
    readonly from: string;
    readonly to: string;
    readonly type: string;
}
interface SearchResult {
    readonly queries: {
        readonly [_key: string]: QueryExplainResult;
    };
}
interface ExecutionStateGlobalOverride {
    readonly timerange: TimeRange;
    readonly keep_search_types: string[];
    readonly offset: number;
    readonly query: BackendQuery;
    readonly limit: number;
    readonly search_types: {
        readonly [_key: string]: SearchTypeExecutionState;
    };
    readonly keep_queries: string[];
}
interface ExplainResults {
    readonly search: SearchResult;
    readonly search_errors: SearchError[];
    readonly search_id: string;
}
interface TimeRange {
    readonly type: string;
}
interface Filter {
    readonly filters: Filter[];
    readonly type: string;
}
interface SearchError {
    readonly description: string;
}
interface SearchTypeExecutionState {
    readonly per_page: number;
    readonly offset: number;
    readonly limit: number;
    readonly page: number;
    readonly after: unknown[];
}
interface Parameter {
    readonly name: string;
    readonly data_type: string;
    readonly binding: Binding;
    readonly description: string;
    readonly optional: boolean;
    readonly default_value: Object;
    readonly title: string;
    readonly type: string;
}
interface Object {
}
interface BackendQuery {
}
interface IndexRangeResult {
    readonly is_warm_tiered: boolean;
    readonly end: number;
    readonly index_name: string;
    readonly begin: number;
}
/**
 * Create a search query
 */
export function createSearch(arg0?: SearchDTO): Promise<SearchDTO> {
    return __request__('POST', '/views/search', arg0, {}, {
        'Accept': [
            'application/json',
            'application/vnd.graylog.search.v1+json'
        ]
    });
}
/**
 * Create a search query
 */
export function createSearchV2(arg0?: SearchDTOv2): Promise<SearchDTOv2> {
    return __request__('POST', '/views/search', arg0, {}, {
        'Accept': ['application/vnd.graylog.search.v2+json']
    });
}
/**
 * Get all searches which the user may see
 */
export function getAllSearches(): Promise<SearchDTOArray> {
    return __request__('GET', '/views/search', null, {}, {
        'Accept': [
            'application/json',
            'application/vnd.graylog.search.v1+json'
        ]
    });
}
/**
 * Retrieve the status of an executed query
 */
export function jobStatus(jobId?: string): Promise<SearchJobDTO> {
    return __request__('GET', `/views/search/status/${jobId}`, null, {}, {
        'Accept': [
            'application/json',
            'application/vnd.graylog.search.v1+json'
        ]
    });
}
/**
 * Execute a new synchronous search
 */
export function executeSyncJob(arg0?: SearchDTO, timeout: number = 60000): Promise<SearchJobDTO> {
    return __request__('POST', '/views/search/sync', arg0, { 'timeout': timeout }, {
        'Accept': [
            'application/json',
            'application/vnd.graylog.search.v1+json'
        ]
    });
}
/**
 * Execute a new synchronous search
 */
export function executeSyncJobv2(arg0?: SearchDTOv2, timeout: number = 60000): Promise<SearchJobDTO> {
    return __request__('POST', '/views/search/sync', arg0, { 'timeout': timeout }, {
        'Accept': ['application/vnd.graylog.search.v2+json']
    });
}
/**
 * Retrieve a search query
 */
export function getSearch(id?: string): Promise<SearchDTO> {
    return __request__('GET', `/views/search/${id}`, null, {}, {
        'Accept': [
            'application/json',
            'application/vnd.graylog.search.v1+json'
        ]
    });
}
/**
 * Execute the referenced search query asynchronously
 */
export function executeQuery(id?: string, arg1?: ExecutionState): Promise<SearchJobDTO> {
    return __request__('POST', `/views/search/${id}/execute`, arg1, {}, {
        'Accept': [
            'application/json',
            'application/vnd.graylog.search.v1+json'
        ]
    });
}
/**
 * Explains how the referenced search would be executed
 */
export function explainQuery(id?: string, arg1?: ExecutionState): Promise<ExplainResults> {
    return __request__('POST', `/views/search/${id}/explain`, arg1, {}, {
        'Accept': ['application/json']
    });
}
